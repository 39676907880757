import WindowProps from './WindowProps.js';

function InView(element, offset) {
  // if(!element)return false;
  let singlePageOpen = document.querySelector('.page--single') || false;

  var bodyRect = singlePageOpen ? singlePageOpen.getBoundingClientRect() : document.body.getBoundingClientRect();
  var rect = element.getBoundingClientRect();


  const elementTop = rect.y - bodyRect.y;
  offset = (!offset) ? 0 : offset;
  var trigger_point = WindowProps.SCROLLTOP + WindowProps.SIZE.height - offset;

    if ( trigger_point > elementTop ){
      return true;
    }
    else {
      return false;
    }
}

export default InView;
